import React from 'react'
import Start from './components/Start'
import Button from './components/Button'
import { decode } from 'html-entities';

function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
    return array
}

export default function App() {
    
    const [ gameState, setGameState ] = React.useState({
                                                            isStarted: false,
                                                            isReseted: false,
                                                            isChecked: false,
                                                            score: 0    
                                                        })
                                                        
    const [ allQuestionAnswerPairs, setAllQuestionAnswerPairs ] = React.useState([])
    const [ reset, setReset ] = React.useState(false)
    
    
    const question_blocks = allQuestionAnswerPairs.map( ( question, index ) => 
            <div className='question' >
                <h3 className='question--title' >{question.question}</h3>
                <div className='question--answers' >
                    { question.answers.map( answer => <Button data={answer} key={answer.key} /> ) }
                </div>
            </div>
    ) 
   
    

    React.useEffect( () =>{
        fetch('https://opentdb.com/api.php?amount=5')
            .then( res => res.json() )
                .then( res => {
                    setGameState( prevState => ({
                        ...prevState,
                        isReseted: false,
                        isChecked: false,
                        score: 0
                    }) )
                    setAllQuestionAnswerPairs( res.results.map( ( questionAnswerPair, question_index ) => ({
                    index: question_index,
                    question: decode(questionAnswerPair.question),
                    answers: shuffleArray(questionAnswerPair.incorrect_answers.concat( questionAnswerPair.correct_answer)).map( ( answer, answer_index ) => ({
                        key: `${question_index}${answer_index}`,
                        questionIndex: question_index,
                        answerIndex: answer_index,
                        answer: decode(answer),
                        isCorrect: decode(questionAnswerPair.correct_answer) === decode(answer) ? true : false,
                        isSelected: false,
                        handleClick: button,
                        className:decode(questionAnswerPair.correct_answer) === decode(answer) ? 'correct--answer unchecked unselected' : 'incorrect--answer unchecked unselected',
                    }) )
                }) ) )} )
        
    }, [ reset ])
    
    /* console.log(allQuestionAnswerPairs) */
   
    function button( answer ) {
        setGameState( prevState => {
            
            if ( !prevState.isChecked ){
                setAllQuestionAnswerPairs( pairs => {
            
                    const currentAnswer = pairs[answer.questionIndex].answers[answer.answerIndex]
                    
                    for (let i = 0; i < pairs[answer.questionIndex].answers.length; i++) {
                        pairs[answer.questionIndex].answers[i].className = pairs[answer.questionIndex].answers[i].className.replace(' selected', ' unselected')
                        pairs[answer.questionIndex].answers[i].isSelected = false
                    }
                    
                    currentAnswer.className = currentAnswer.isSelected ? currentAnswer.className.replace(' selected', ' unselected') : currentAnswer.className.replace( ' unselected', ' selected' )
                    
                    currentAnswer.isSelected = true
                    
                    return pairs.map( e => e )
                } )
            }
            
            return prevState
        } )
            
        
        
        /* setAllQuestionAnswerPairs( pairs => {
            console.log(pairs[answer.questionIndex].answers[answer.answerIndex])
            return pairs
        } ) */
    }
    /* console.log(allQuestionAnswerPairs) */
    
    
    function checkButton() {
        setAllQuestionAnswerPairs( pairs => {
            
            
            for (let j = 0; j < pairs.length; j++) {
                for (let i = 0; i < pairs[j].answers.length; i++) {
                    pairs[j].answers[i].className = pairs[j].answers[i].className.replace(' unchecked', ' checked')
                    /* console.log(pairs[j].answers[i]) */
                    if ( pairs[j].answers[i].isCorrect && pairs[j].answers[i].isSelected ) {
                        
                        setGameState( prevState => {
                            return {
                                        isStarted: true,
                                        isChecked: true,
                                        score: prevState.score + 1
                                    } 
                        } )
                    }
                    
                    /* pairs[j].answers[i].isCorrect && pairs[j].answers[i].isSelected ? gameState( prevState => ({
                        ...prevState,
                        score: (prevState.score + 1)
                    }) ) : console.log(pairs[j].answers[i]) */
                    /* console.log(pairs[j].answers[i]) */
                }
            }
            /* console.log(currentAnswer) */
            
            return pairs.map( e => e )
        } )
        setGameState( prevState => ({
            ...prevState,
            isChecked: true
        }) )
    }
    
    function playAgainButton() {
        
        if( !gameState.isReseted ){
            setReset( prevState => !prevState )
            setGameState(  prevState => ({
                ...prevState,
                isReseted: true
            }) )
        }
        
    }
    
    function startButton() {
        setGameState( prevState => ({
            ...prevState,
            isStarted: true
        }) )
    }
    
    const playAgain = <Button
                        data={{
                                className: 'game--button button', 
                                handleClick: playAgainButton, 
                                answer: 'Play Again'
                            }} /> 
                            
    const check =  <Button 
                        data={{
                                className: 'game--button button', 
                                handleClick: checkButton, 
                                answer: 'Check'
                            }} />                  
    
    return (
        <>
            
            { gameState.isStarted ? 
            <div className='question--area--container' >
                <div className='question--area' >
                { question_blocks }
                { gameState.isChecked  ? 
                <div className='question--area--bottom' >
                    {playAgain} 
                    <h3 className='you--scored' >You scored {gameState.score} / 5</h3>
                </div> : 
                <div className='question--area--bottom' >
                    {check}
                </div>
                }
                </div>
            </div> : 
            <Start handleClick={ startButton } /> }
            
            
        </>
    )
}