import React from 'react'
import Button from './Button'

export default function Start(props) {
    /* 
    const startButton = buttonTemplate( 'Start Quiz', 'start--button button', 1, props.handleClick )
     */
    return (
        <div className='start' >
            <h1 className='start--header' >Quizzical</h1>
            <h5 className='start--description' >Some description if needed</h5>
            <div className='game--button button' onClick={props.handleClick} >Start Quiz</div>
        </div>
    )
}